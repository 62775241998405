<template>
    <v-container>
       
        <v-row>
            <v-col
            class="mx-auto"
            cols="12"
            sm="8">
                <div class="d-flex justify-space-between">
                    <v-btn
                    class="mb-3"
                    color="primary"
                    @click="closeDetail">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                    <v-btn color="primary" v-if="this.$store.getters.getRole=='operation' || this.$store.getters.getRole=='developer'" @click="redirectTaskEdit">
                        Edit
                    </v-btn>
                </div>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar color="secondary white--text" flat class="text-h4 ">
                        Task
                    </v-toolbar>
                    <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">

                    </v-skeleton-loader>
                    <v-container v-if="task!=null">
                        <div class="mt-3 font-weight-bold " >
                            {{ this.task.task_name }}   
                        </div>

                        <!-- <div class="mt-3 d-flex justify-start">
                            Assigned to :
                            <a @click="redirectReadUser(task.pic_id)" class="text-decoration-none ml-3">
                                 {{ this.task.pic_name }}
                            </a>
                        </div> -->
                        <div class="mt-3 d-flex justify-start">
                            Company :
                            <!-- <a @click="redirectReadCompany(task.company_id)" class="text-decoration-none ml-3">
                                 {{ this.task.company_name }}
                            </a> -->
                            <router-link :to="{name:'PageCompaniesDetail',params:{id:this.task.company_id}}">
                                {{ this.task.company_name }}
                            </router-link>

                        </div>  
                        
                        <v-divider class="my-4">

                        </v-divider>
                        <div class="mt-3">
                            <v-select disabled dense outlined label="Status" :items="optionTaskStatus" v-model="task.task_status" @change="updateTaskStatus">

                            </v-select>
                            <v-progress-circular v-if="api.isLoading" :size="50" color="primary" indeterminate>

                            </v-progress-circular>
                        </div>
                        <div class="mt-3">
                            Priority : 
                            <v-chip v-if="this.task.priority=='Urgent'" class="font-weight-bold" color="red white--text">
                                    {{ this.task.priority }}
                            </v-chip>
                            <v-chip class="font-weight-bold" v-else>
                                {{ this.task.priority }}
                            </v-chip>
                        </div>
                        <div class="mt-3 d-flex justify-start">
                            Category : <p class="ml-3 font-weight-bold">{{ this.task.task_category }}</p>
                        </div>
                        <div class="mt-3 d-flex justify-start">
                            Assigned at : <p class="ml-3 font-weight-bold">{{ this.task.created_at }}</p>
                        </div>
                        <div class="mt-3 d-flex justify-start" v-if="task.deadline_at!=null">
                            Deadline : <p class="ml-3 font-weight-bold">{{ this.task.deadline_at.split(' ')[0] }}</p>
                        </div>

                        
                        <div class="mt-3">
                            <p>
                                Action
                            </p>
                            <mavon-editor
                            style="z-index:0;min-height:100px;height:auto;width:100%"
                            width="auto"
                            defaultOpen="preview"
                            :toolbarsFlag="false"
                            :subfield="false"
                            language="en"
                            v-model="this.task.task_action"
                            v-if="this.task.task_action != null"
                            :editable="false">
                            </mavon-editor>
                        </div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import mavonEditor from 'mavon-editor';
export default {
    components:{
    "mavon-editor":mavonEditor.mavonEditor,
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    task:null,
    teamDescription:null,
    teams:null,
    headersTeam:[
        {
            text:'Name',
            value:'memberName',
            align:'start',
        },{
            text:'Email',
            value:'memail',
        }
    ],
    api:{
        isSuccesful:false,
        isLoading:false,
        isError:false,
        error:null,
        sucess:null,
        url:null,
    },
    optionTaskStatus:[
    'To do','In progress','In review','Done'
    ],
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getTask"){
                this.task = resp.data;
            }
            this.api.isSuccesful = true;
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchTaskApi = this.fetchTask();
            this.$api.fetch(fetchTaskApi);
        },
        fetchTask(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/id/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchUpdateStatus(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/id/"+this.$router.history.current.params.id;
            let formData = new FormData();
            formData.append('status',this.task.task_status);
            tempApi.params = formData;
            return tempApi;
        },
        redirectReadUser(userId){
            this.$router.push({name:'PageStaffDetail',params:{id:userId}});
        },
        redirectReadCompany(companyId){
            this.$router.push({name:'PageCompaniesDetail',params:{id:companyId}});
        },
        redirectTaskEdit(){ 
            this.$router.push({name:'PageTaskUpdate',params:{id:this.$router.history.current.params.id}});
        },
        updateTaskStatus(){
            let fetchUpdateStatusApi = this.fetchUpdateStatus();
            this.$api.fetch(fetchUpdateStatusApi);

        },
        closeDetail(){
            this.$router.go(-1);
        },
    }
}
</script>